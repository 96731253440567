.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  // opacity: 0;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;

  svg {
    width: 100%;
    height: auto;
    bottom: 0;
    transform: rotateZ(30deg) !important;
  }

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    // opacity: 0;
    // transform: rotateZ(30deg) !important;
    // z-index: 1;
    animation: slideIn 2s forwards;
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}
.circle1 {
  -webkit-animation: animate 2500s linear infinite;
  animation: animate 2500s linear infinite;
  border: 2px dotted #ffd700;
  border-radius: 50%;
  border-spacing: 20px;
  box-sizing: border-box;
  height: 63%;
  // -webkit-transform: translate(-50%,-50%);
  // transform: translate(-50%,-50%);
  width: 400px;
}
.circle2 {
  -webkit-animation: circle1 5000s linear infinite;
  animation: circle1 5000s linear infinite;
  border: 2px solid #ffd700;
  border-radius: 50%;
  border-spacing: 20px;
  height: 59%;
  left: 15px;
  position: absolute;
  // top: 15px;
  width: 90%;
}
