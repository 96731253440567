.portfolio-page {
  .tilt_wrapper {
    width: 360px;
    border-radius: 1rem;
    padding: 1.25rem;
    opacity: 1;
    background-color: rgb(4 24 34);
    margin-bottom: 10px;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
  }
  .stage-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    height: 110%;
    overflow: auto;

    width: 70%;
    margin-left: auto;
  }
  .header_card {
    color: white;
    font-weight: 700;
    font-size: 24px;
    margin: 5px 0;
  }
  .header_card_para {
    font-size: 14px !important;
    color: white;
  }
  .me_logo_container {
    position: absolute;
    margin-top: 3px;
    top: 5px;
    right: 8px;
    background: linear-gradient(to right, #012c43, #ffd602);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
  .relative-pos {
    position: relative;
  }
  .projrct_card_container {
    display: flex;
    justify-content: start;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 100vh;
    margin: 10px 0px 20px 0px;
  }
  .hash_container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    .logo0 {
      background: #56ccf2;
      background: linear-gradient(to top, #2f80ed, #56ccf2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: 2px;
      margin: 0;
    }

    .logo1 {
      background: linear-gradient(to top, #11998e, #38ef7d);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: 2px;
      margin: 0;
    }

    .logo2 {
      background: linear-gradient(to top, #ec008c, #fc6767);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: 2px;
      margin: 0;
    }
  }
}
@media screen and (max-width: 1200px) {
  .portfolio-page {
    .tilt_wrapper {
      width: auto;
      border-radius: 1rem;
      padding: 1.25rem;
      opacity: 1;
      background-color: rgb(4 24 34);
      margin-bottom: 15px;
      position: relative;
    }
    .stage-container {
      width: 100%;
    }
  }
}
